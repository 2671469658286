import { useState } from "react"
import { useStateContext } from "../StateProvider"
import { getDateValue } from "../helperFunctions"


const HouseOfCards = () => {

    const [showWords, setShowWords] = useState(false)
    const [showTrad, setShowTrad] = useState(false)

    const {learnedCards, setShowCard,  setShowModal} = useStateContext()
    const today = new Date()
    
    const formatedCards = learnedCards.map(e => {
        const nextDate = getDateValue(e.profile_card.next_date);
        const state = e.archived ? "archived" : nextDate <= today ? "refresh" : "mastered";
        return {...e.card, state: state, profile_card: e.profile_card};
    });




    return <div className="fixed z-[62] top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-indigo-900 to-purple-900">
         <div onClick={(e) => e.stopPropagation()} className="text-white h-full overflow-scroll p-4">
         <div onClick={() => {setShowModal()}}   className="absolute top-4 right-4 text-white z-40 bg-red-500 p-1 rounded-xl border border-b-4 border-red-700">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        </div>
      <div className="mb-4 my-2 ">
       
     
    
        <h2 className="text-white">Liste des mots appris</h2>
        {/* <div className="" onClick={() =>{setShowTrad(!showTrad)}}>Voir les {showTrad? "Termes" : "Traductions"}</div> */}
      </div>
      <div className="flex flex-wrap gap-1">
        {formatedCards?.filter(c => c?.term)?.map(c => <div onClick={() => {
setShowCard({card: c, profile_card: c.profile_card })
        }} className={` ${c.state == "mastered" ? "text-emerald-200 bg-emerald-500/10 border-emerald-600/70" : "text-indigo-200 bg-indigo-500/10 border-[#1a185ce6]"} px-2 rounded-md flex gap-1 items-center text-sm border-[1.6px] border-b-[2.4px] `}>{ showTrad ? c.trad : c.term} {c.profile_card?.hard && <span className="bg-pink-500/20 text-xs text-white rounded-full px-1">!</span>} 
        {c.hint && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-4">
  <path d="M15.98 1.804a1 1 0 0 0-1.96 0l-.24 1.192a1 1 0 0 1-.784.785l-1.192.238a1 1 0 0 0 0 1.962l1.192.238a1 1 0 0 1 .785.785l.238 1.192a1 1 0 0 0 1.962 0l.238-1.192a1 1 0 0 1 .785-.785l1.192-.238a1 1 0 0 0 0-1.962l-1.192-.238a1 1 0 0 1-.785-.785l-.238-1.192ZM6.949 5.684a1 1 0 0 0-1.898 0l-.683 2.051a1 1 0 0 1-.633.633l-2.051.683a1 1 0 0 0 0 1.898l2.051.684a1 1 0 0 1 .633.632l.683 2.051a1 1 0 0 0 1.898 0l.683-2.051a1 1 0 0 1 .633-.633l2.051-.683a1 1 0 0 0 0-1.898l-2.051-.683a1 1 0 0 1-.633-.633L6.95 5.684ZM13.949 13.684a1 1 0 0 0-1.898 0l-.184.551a1 1 0 0 1-.632.633l-.551.183a1 1 0 0 0 0 1.898l.551.183a1 1 0 0 1 .633.633l.183.551a1 1 0 0 0 1.898 0l.184-.551a1 1 0 0 1 .632-.633l.551-.183a1 1 0 0 0 0-1.898l-.551-.184a1 1 0 0 1-.633-.632l-.183-.551Z" />
</svg>
} </div>)}
      </div>

    </div>
  </div>
 
}


export {HouseOfCards}