import { useEffect, useState } from "react";
import { comparerSansAccents } from "../../../utilities/helper/helper";
import { mp3_bad, mp3_valid } from "../../helperData";
import { getTextBetweenBrackets, hanziToPinyin, speak } from "../../helperFunctions";
import { useStateContext } from "../../StateProvider";
const normalize = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

const InputComponent = ({text, validCard, inputClassName, hintLvl, speak, setState}) => {

    const [textInput, setTextInput] = useState()
    const [startTime, setStartTime] = useState()
    const [error, setError] = useState(0)
    const time = 3 + 0.33*text?.length
    const [hint, setHint] = useState(0)
    const {workspace} = useStateContext()

    useEffect(() => {
        if (hintLvl == 0) {
            setHint(0)
        }
        if (hintLvl == 1) {
            setHint(1)
        }
        if (hintLvl == 2) {
            setHint(Math.ceil(text.length/2))
        }
        if (hintLvl == 3) {
            setHint(Math.ceil(text.length))
        }
    }, [hintLvl, text])

    useEffect(() => {
        if (hint > 0) {
            const bg = document.querySelector('#bganswer')
            bg.value = ""
        }
    }, [hint])

    useEffect(() => {
        console.log('nouveau !!')
        setTextInput("")
        
        setStartTime(new Date())
    }, [])

    const handleChange = (value) => {
        const hints = document.querySelectorAll('.hint-letter')

        const comparison =  value && value.toLowerCase()?.trim()?.split('').map((letter, index) => {
            return letter === text.toLowerCase().trim()?.[index] ? 1 : letter == normalize(text.toLowerCase().trim()?.[index]) ? 0.5 : 0;
        });

        hints.forEach((hint, index) => {
            if (comparison?.[index] === 1) {
                hint.style.backgroundColor = '#18b5be';
                hint.style.transform = 'translateY(2px)';

            } else if (comparison?.[index] === 0.5) {
                hint.style.backgroundColor = '#f08567';
                hint.style.transform = 'translateY(2px)';
            }
             else {
                hint.style.transform = 'translateY(0px)';
                hint.style.backgroundColor = ''; // Réinitialiser la couleur si la lettre est incorrecte
            }
        });

        console.log('comparison', comparison)
        if (value?.trim().length != text?.trim().length) return
        const diffInMs = new Date() - startTime  
        const bonusTest = diffInMs/1000 < time && hintLvl == 0
        console.log('diffInMs', diffInMs)
        
        console.log('textInput', textInput)
        console.log('text', text)
        // const goodWithoutAccent = value !== answer && comparerSansAccents(value || "", answer || "");
        if (value.toLowerCase() == text.toLowerCase() || comparerSansAccents(value || "", text || "")) {
            let xpBonus = bonusTest ? 2 : 1
            if (hintLvl == 3) xpBonus = 0
            
            const bg = document.querySelector('#bganswer')
            bg.classList.add('text-blink');
            const xpRef = document.querySelector('#bonusInput')

            if (xpBonus) {
                xpRef.innerHTML = `<div class="absolute flex gap-1 items-center pointer-events-none add-xp">+${xpBonus} <img src="/images/icons/coin-xp.png" class="h-6" /></div>`
            }
            mp3_valid.play()

            speak && speak()

            if (setState) {
                // mode révision
                setTimeout(() => {
                    bg.classList.remove('wizz');
                    console.log('__blink c validé avec state' )
                    validCard(xpBonus)
                    !bonusTest && setState(1)
                    
                  }, 500);

            } else {
                 // mode classique
                console.log('__blink c validé sans state' )
                setTimeout(() => {
                    validCard({valid: true})
                }, [300])
            }
            
            
 
        
        } else if (value?.trim().length == text?.trim().length){ 
            console.log('FAUXXX')
            
            mp3_bad.play()
            const bg = document.querySelector('#bganswer')
            bg.classList.add('wizz');
            setTimeout(() => {
                bg.classList.remove('wizz');
              }, 300); // Durée de l'animation

        }
    
    }
      

    // faire animation du +1 au clique 

    let fontSize = "text-xl" 
    let ratio = 1
    if (text.length > 16) {
        fontSize = "text-base"
        ratio = 1.25
    }
    if (text.length > 20) {
        fontSize = "text-md"
        ratio = 1.3
    }
    if (text.length > 26) {
        fontSize = "text-sm"
        ratio = 1.3
    }
    if (text.length > 30) {
        fontSize = "text-xs"
        ratio = 1.4
    }

    if (text.length < 10) {
        fontSize = "text-2xl"
        ratio = 0.85
    }

    return <div className="inline-block relative">
        <div id={"bonusInput"} onClick={(e) => {e.preventDefault()}} className={`absolute pointer-events-none -top-[14px] text-cyan-500 h-[31px] -right-1 rounded-md  z-10`} style={{pointerEvents: "none" }}></div>

        <div onClick={(e) => {e.preventDefault()}} className={`absolute -top-[3px] text-cyan-500 h-[31px] -right-2 rounded-md  z-10`} style={{ animation: `ciao 0.4s ease-in forwards ${time- 0.2}s`, pointerEvents: "none" }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4">
                <path fillRule="evenodd" d="M5 4a.75.75 0 0 1 .738.616l.252 1.388A1.25 1.25 0 0 0 6.996 7.01l1.388.252a.75.75 0 0 1 0 1.476l-1.388.252A1.25 1.25 0 0 0 5.99 9.996l-.252 1.388a.75.75 0 0 1-1.476 0L4.01 9.996A1.25 1.25 0 0 0 3.004 8.99l-1.388-.252a.75.75 0 0 1 0-1.476l1.388-.252A1.25 1.25 0 0 0 4.01 6.004l.252-1.388A.75.75 0 0 1 5 4ZM12 1a.75.75 0 0 1 .721.544l.195.682c.118.415.443.74.858.858l.682.195a.75.75 0 0 1 0 1.442l-.682.195a1.25 1.25 0 0 0-.858.858l-.195.682a.75.75 0 0 1-1.442 0l-.195-.682a1.25 1.25 0 0 0-.858-.858l-.682-.195a.75.75 0 0 1 0-1.442l.682-.195a1.25 1.25 0 0 0 .858-.858l.195-.682A.75.75 0 0 1 12 1ZM10 11a.75.75 0 0 1 .728.568.968.968 0 0 0 .704.704.75.75 0 0 1 0 1.456.968.968 0 0 0-.704.704.75.75 0 0 1-1.456 0 .968.968 0 0 0-.704-.704.75.75 0 0 1 0-1.456.968.968 0 0 0 .704-.704A.75.75 0 0 1 10 11Z" clipRule="evenodd" />
            </svg>
        </div>
        <div onClick={() => {
        console.log('click input')
       
    }} key={"container_"+text} className={`relative my-1 mb-[-4px] overflow-hidden h-[36px] ${fontSize} rounded-md   mx-1 font-mono inline-block`}>
        
        

        <div className='absolute -bottom-[0px] pointer-events-none	 h-[36px]  justify-end flex w-full grow z-[1]'><div onClick={(e) => {e.preventDefault()}} className={` flex   h-[30px] rounded bg-purple-500/10 pointer-events-none	  z-10`} style={{ width: '0%', animation: `unfillBar ${time}s ease-in forwards`, pointerEvents: "none" }}></div></div>




        <input 
            id="bganswer"
            autoFocus={true}
            className={`font-mono ${workspace.lang == "ar-XA" ? "text-right" : ""} text-indigo-500 bg-[#ffeac6] rounded bg-transparent  px-[6px] outline-none !tracking-widest focus:outline-none ${inputClassName}`}
            key={text}
            type="text"
            autoComplete="off" 
            autoCorrect="off"
            // placeholder={text[0] + " ".repeat(text.length - 1)}
            onChange={(e) => {handleChange(e.target.value)}}
            style={{ width: `calc(${text.length*(14/ratio)}px + 12px) `}}
            />
            
            {/* <input 
            className="font-mono pointer-events-none rounded  bg-transparent px-[6px] z-10 outline-none text-indigo-500 !tracking-widest	focus:outline-none absolute left-0 right-0 top-[4px] tracking-wide"
            key={"shadow_2"+text}
            type="text"
            value={"_".repeat(text.length) }
            style={{ width: `calc(${text.length*(14/ratio)}px + 12px) `}}
            /> */}
            <div className={`duration-200 absolute bottom-0 left-0 right-0 min-h-[4px] h-[4px] max-h-[4px] overflow-hidden flex ${workspace.lang == "ar-XA" ? "flex-row-reverse" : ""} gap-[1px] transition-transform bg-indigo-500/1-0 rounded-xl px-[5px]`} style={{ width: `calc(${text.length*(14/ratio)}px + 12px) `}}>
                {text.split('')?.map((e,i) => <div id={"l"+i} className={`h-[2px] hint-letter ${e == " " && "opacity-[0.3]"} rounded-xl bg-indigo-500 grow`}></div>)}
            </div>
            <input 
            className={`font-mono ${workspace.lang == "ar-XA" ? "text-right" : ""} pointer-events-none rounded  bg-transparent px-[6px] z-10 outline-none text-pink-500/50 !tracking-widest	focus:outline-none absolute left-0 right-0 top-[0px] tracking-wide`}
            key={"hint_2"+text}
            type="text"
            value={text.slice(0, hint) + " ".repeat(text.length - (1 - hint))}
            style={{ width: `calc(${text.length*(14/ratio)}px + 12px) `}}
            />

</div></div>
}

const FillSentence = ({brackedSentence, validCard, inputClassName, speak, setState, isNew, setDifficulty}) => {

    const parts = brackedSentence?.split(/\[(.*?)\]/);
    const text = getTextBetweenBrackets(brackedSentence)
    const {workspace} = useStateContext()

    const [hintLvl, setHintLvl] = useState(0)

    useEffect(() => {
        setHintLvl(0)

    }, [brackedSentence])

    let pinyinSentence = null
    if (workspace.name == "Chinois") {
        const pinyin = hanziToPinyin(text, {
            toneType: 'symbol',
            type: 'array',
            compact: true
        })

        pinyinSentence = hanziToPinyin(brackedSentence, {
            toneType: 'symbol',
            type: 'array',
            compact: true
        }).replace(pinyin, "_".repeat(pinyin.length))?.replace(/[\[\]]/g, '')
    }

       
    return (<>
        <div className="text-base text-indigo-800/80">
            {parts?.map((part, index) => {
                // Si l'index est impair, c'est le contenu entre crochets
                // donc on le remplace par un input
                if (index % 2 === 1) {
                    return <InputComponent setState={setState} speak={speak} hintLvl={hintLvl} inputClassName={inputClassName} key={part} validCard={validCard} size={2}  
                    text={part} 
                 
                    />;
                }
                // Sinon on affiche le texte tel quel
                return <span key={index}>{part}</span>;
            })}
        </div>
      
        {pinyinSentence && <div className="text-sm font-sans opacity-50">{pinyinSentence}</div>}





        <div className={`${!isNew ? "bg-purple-300/50 pattern-wiggle" : "pattern-triangle rounded-b-xl bg-[#ffeac6]"}  absolute  flex gap-4 items-center justify-center bottom-0 left-0 right-0 h-[100px]`}>
            
        <div className={`${isNew ? "text-amber-600/90" : "text-purple-500/80"} `} onClick={() => {
                   
                   if (setDifficulty) return setDifficulty(3)
                   if (isNew) {
                       validCard({valid: true})
                   } else {
                       
                       validCard(0)
                       setState(1)
                   }
                   }} >Je ne sais plus </div>
            {hintLvl < 2 && <div onClick={() => {
                setHintLvl( prev => prev + 1 > 2 ?  3 : prev + 1 )
            }} className={`${!isNew ? "text-purple-500/70 border-purple-500/50": "text-amber-600 border-amber-600/70"} px-3 bg-white rounded-xl  border-2 flex gap-1 border-b-4  py-1`}> {hintLvl == 2 ? "Voir la réponse":  hintLvl == 1 ? "Plus d'indice" : "Voir un indice"}<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                </svg>
                </div>}
               
        </div>
        </>
        
    );
};

export {FillSentence};