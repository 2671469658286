import { useEffect, useState } from "react";
import { supabase } from "./testScreen";
import { displayDuration, fetch50k, numberSentences, readingTime } from "../../V3/helperFunctions";
import { quantum, waveform } from 'ldrs'
import { SuperText } from "../../V3/components/SuperText";
import { useStateContext } from "../../V3/StateProvider";
import { gpt } from "../../utilities/helper/text";
import { sbUpdate } from "../../V3/DbFunction";
quantum.register()




// const get = "https://newsapi.ai/api/v1/event/getEvents?query=%7B%22%24query%22%3A%7B%22%24and%22%3A%5B%7B%22locationUri%22%3A%22http%3A%2F%2Fen.wikipedia.org%2Fwiki%2FFrance%22%7D%2C%7B%22lang%22%3A%22fra%22%7D%5D%7D%2C%22%24filter%22%3A%7B%22forceMaxDataTimeWindow%22%3A%2231%22%7D%7D&resultType=events&eventsSortBy=size&includeEventSummary=true&includeEventStories=true&eventImageCount=1&includeConceptImage=true&includeConceptDescription=true&includeConceptTrendingScore=true&includeStoryTitle=true&storyImageCount=1&apiKey=18a1130a-5a57-4807-b32e-079e58bd5c23&callback=JSON_CALLBACK"


const url = "https://www.newsapi.ai/api/v1/article/getArticles"

const get = "https://newsapi.ai/api/v1/event/getEvents?query=%7B%22%24query%22%3A%7B%22%24and%22%3A%5B%7B%22locationUri%22%3A%22http%3A%2F%2Fen.wikipedia.org%2Fwiki%2FFrance%22%7D%2C%7B%22lang%22%3A%22fra%22%7D%5D%7D%2C%22%24filter%22%3A%7B%22forceMaxDataTimeWindow%22%3A%2231%22%7D%7D&resultType=events&eventsSortBy=size&includeEventSummary=true&includeEventStories=true&eventImageCount=1&includeConceptImage=true&includeConceptDescription=true&includeConceptTrendingScore=true&includeStoryTitle=true&storyImageCount=1&apiKey=18a1130a-5a57-4807-b32e-079e58bd5c23";

const get2 = "https://newsapi.ai/api/v1/event/getEvents?query=%7B%22%24query%22%3A%7B%22%24and%22%3A%5B%7B%22locationUri%22%3A%22http%3A%2F%2Fen.wikipedia.org%2Fwiki%2FFrance%22%7D%2C%7B%22lang%22%3A%22fra%22%7D%5D%7D%2C%22%24filter%22%3A%7B%22forceMaxDataTimeWindow%22%3A%2231%22%7D%7D&resultType=events&eventsSortBy=size&includeEventSummary=true&includeEventSocialScore=true&includeEventCommonDates=true&includeEventStories=true&eventImageCount=1&includeConceptImage=true&includeConceptDescription=true&includeConceptSynonyms=true&includeConceptTrendingScore=true&includeSourceDescription=true&includeSourceLocation=true&includeSourceRanking=true&includeStoryBasicStats=true&includeStoryTitle=true&includeStoryLocation=true&includeStoryDate=true&includeStorySocialScore=true&includeStoryConcepts=true&includeStoryCategories=true&includeStoryMedoidArticle=true&includeStoryCommonDates=true&storyImageCount=1&apiKey=18a1130a-5a57-4807-b32e-079e58bd5c23"
const get3 = "https://newsapi.ai/api/v1/event/getEvents?query=%7B%22%24query%22%3A%7B%22%24and%22%3A%5B%7B%22locationUri%22%3A%22http%3A%2F%2Fen.wikipedia.org%2Fwiki%2FFrance%22%7D%2C%7B%22lang%22%3A%22fra%22%7D%5D%7D%2C%22%24filter%22%3A%7B%22forceMaxDataTimeWindow%22%3A%2231%22%7D%7D&resultType=events&eventsSortBy=size&includeEventSummary=true&includeEventSocialScore=true&includeEventCommonDates=true&includeEventStories=true&eventImageCount=1&includeConceptImage=true&includeConceptDescription=true&includeConceptSynonyms=true&includeConceptTrendingScore=true&includeSourceDescription=true&includeSourceLocation=true&includeSourceRanking=true&includeStoryBasicStats=true&includeStoryTitle=true&includeStoryLocation=true&includeStoryDate=true&includeStorySocialScore=true&includeStoryConcepts=true&includeStoryCategories=true&includeStoryMedoidArticle=true&includeStoryCommonDates=true&storyImageCount=1&apiKey=18a1130a-5a57-4807-b32e-079e58bd5c23"
const fetchNews = () => {
    return new Promise((resolve, reject) => {
        // Créer un élément script
        const script = document.createElement('script');
        
        // Définir une fonction callback globale
        window.JSON_CALLBACK = function(data) {
            // Nettoyer
            document.body.removeChild(script);
            delete window.JSON_CALLBACK;
            resolve(data);
        };

        // Configurer le script
        script.src = get3 + "&callback=JSON_CALLBACK";
       script.onerror = () => {
            // Nettoyer en cas d'erreur
            document.body.removeChild(script);
            delete window.JSON_CALLBACK;
            reject(new Error('Failed to load news data'));
        };

        // Ajouter le script au document
        document.body.appendChild(script);
    });
};

// faire le filtrage avec les articles déjà existants 
// faire filtrage par ville, lyon, paris, 
const getNews = async (set) => {
    try {
        const dataNew =  await fetchNews();

        console.log('news', dataNew);
        
        const goodFormat = dataNew.events?.results.map(e =>formatEventToSupa(e))

        console.log('goodFormat', goodFormat)

        const { data: existingUris, error: fetchError } = await supabase
            .from('news_events')
            .select('uri')

        if (fetchError) {
            console.error('Error fetching existing Uris:', fetchError)
            return
        }

        // 2. Créer un Set des Uri existants pour une recherche plus rapide
        const existingUriSet = new Set(existingUris.map(item => item.uri))

        // 3. Filtrer goodFormat pour ne garder que les nouveaux Uri
        const newEvents = goodFormat.filter(event => !existingUriSet.has(event.uri))
        console.log('new new ', newEvents)
        console.log('existingUriSet  ', existingUriSet)
        console.log('existingUris  ', existingUris)

        const { data, error } = await supabase
            .from('news_events')
            .insert(newEvents)
    
        if (error) {
            console.error('Error inserting:', error)
        } else {
            console.log('All events have been added successfully')
        }

        console.log('goodFormat', goodFormat)
        set(goodFormat)
        return goodFormat;
    } catch (error) {
        console.error('Error fetching news:', error);
        throw error;
    }
};


const formatEventToSupa = (event) => {
    const story = event.stories.find(story => story.uri && story.uri.startsWith('fra-'));
    const article = story?.medoidArticle
    const goodEventFormat = {
        data: {
            title_fr: numberSentences(event.title.fra),
            title_article_fr: numberSentences(story?.title),
            content_fr: numberSentences(article?.body),
            summary_fr: numberSentences(event.summary.fra),
        }, 
        categories: event.categories?.map(c => c.label), // array
        src: article?.source.title,
        country: event.location.country.label.eng,
        location: event.location.label.eng || article?.label?.eng,
        nb_article: event.totalArticleCount,
       
        uri: event.uri, 
        article_uri: article?.uri, 
        author: article?.authors?.[0]?.name,
        img: article?.image,
        img_event: event.images?.[0],
        source: article?.source?.title,
        date: new Date(article?.dateTime),
        lang: event.lang || article?.lang,
    }
    return goodEventFormat
}


const getEvent = async (set) => {
    // Calcul de la date d'il y a un mois
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    
    // Formatage de la date au format ISO pour la comparaison
    const formattedDate = oneMonthAgo.toISOString();

    const data = await supabase
        .from('news_events')
        .select('*')
        .gte('date', formattedDate) // Sélectionne les dates supérieures ou égales à il y a un mois
        .order('date', { ascending: false }); // Optionnel : trie par date décroissante

    console.log('event', data.data);
    set(data.data);
    return data;
}


const RenderArticle = ({close, article, langCode}) => {


    const {workspace, profile} = useStateContext()



    const [tradMode, setTradMode] = useState()
    const [colorText, setColorText] = useState()

    const darkMode = false
    const loadingTranslation = false

    const ct = {}

    const tuto = {}


    return <div onClick={() => console.log('article.data[`content_${langCode}`]', article.data)} className={`fixed ${darkMode ? "bg-purple-700" : "bg-white"} h-screen bottom-0 top-0 left-0 right-0 z-[100] pb-[100px] max-h-screen overflow-scroll  `}>
    <div className='fixed top-2 right-2 text-blue-900' onClick={() => close()}>
    <div class="absolute top-4 right-4 text-white z-40   bg-red-500 p-1 rounded-xl border border-b-4 border-red-700"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg></div>
    </div>

    
      <div onClick={(e) => e.stopPropagation()} className='max-w-[700px] text-blue-500 p-4 pb-[80px] mx-auto'>
        <img className='rounded-xl mx-auto mt-16 h-[240px] object-cover' src={article.img} />
        <div className='flex text-white justify-end gap-2 mb-4 px-4 -mt-8 '>
          <div className='bg-black/30 backdrop-blur-[20px] rounded-xl px-2 '>{article.src}</div>
          <div className='bg-black/30 backdrop-blur-[20px] rounded-xl text-sm px-2  '>il y a {displayDuration(new Date(article.date), new Date())}</div>

        </div>

       <div></div>
        {!profile?.events?.learn_super_text && <div className='fixed bottom-0 p-4 right-0 left-0'>
        <div className='px-4 py-4 flex gap-2 rounded-xl bg-blue-500 text-white'>
                        <img src={"/images/deco/hand.png"} className='h-[50px]'/> Clique sur un mot pour l'apprendre
            </div>
          </div>}
            
        
       {article.data?.[`title_article_${langCode}`] && <div className='flex gap-2 flex-wrap'>
          <div className={`mt-2 px-2 rounded-xl text-sm ${darkMode ? "bg-white/20" : "bg-blue-500/20"}`} onClick={() => setTradMode(!tradMode)}>{!tradMode ? "Afficher la traduction" : "Cacher la traduction"}</div>
          {/* <div className={`mt-2 px-2 rounded-xl text-sm ${darkMode ? "bg-white/20" : "bg-blue-500/20"}`} onClick={() => setColorText(!colorText)}>{!colorText ? "Colorer les mots appris" : "Affichage classique"}</div> */}
       </div> }


     

        <div className='font-semibold text-2xl mt-8 mb-2 text-slate-800'>{
          <SuperText 
          tradMode={tradMode} 
          imgContext={article.img}
          path={"news"}
          colorText={false}
          text={article.data?.[`title_article_${langCode}`]?.replace(/"/g, '')} 
          darkMode={darkMode}
          trad={article.data?.[`title_article_fr`]?.replace(/"/g, '')} 
          decks={ct?.decks || []} 
        //   cards={ct.cards}  
          ct={ct}/>
       
        }</div>
      

      <div className='text-slate-900'>
        {article.data[`content_${langCode}`] && <SuperText 
          tradMode={tradMode} 
          path={"news"}
          colorText={true}
          imgContext={article.img}
          text={article.data[`content_${langCode}`]} 
          darkMode={darkMode}
          trad={article.data[`content_fr`]} 
          decks={ct?.decks || []} 
          cards={ct.cards}  
          ct={ct}/>}
          </div>
        
  
       


      </div>

      </div>
}

const RenderEventLine = ({event, langCode, setSelectedArticle, translateEvent}) => {



    const [translationLoading, setTranslationLoading] = useState(false)

    const translationAvailable = (event.data[`title_${langCode}`] &&  event.data[`content_${langCode}`])


    return <div onClick={async() => {

        console.log('event', event)
        if (translationAvailable) {
            setSelectedArticle(event) 
        } else {
            setTranslationLoading(true)
            await translateEvent(event); 
            setTranslationLoading(false)
        }
        }} className='text-white p-4  rounded-xl border-2 border-b-4 border-black/30 bg-black/10 mt-2'>
    <div className='flex gap-3 items-center'>
    <img  onError={(e) => {e.target.src = '/images/UX/i_actualite.png'}} className='object-cover rounded-xl w-[76px] h-[76px]' src={event.img || event.img_event} />
    <div>
                <div className="max-h-[96px] ">
                    {!translationLoading && <div className="text-sm font-semibold max-h-[42px] overflow-hidden ">
                        {translationAvailable ? event.data[`title_${langCode}`]?.replace(/\[\d+\]\s*/g, '')?.replace(/\|/g, '') : event.data[`title_fr`]?.replace(/\[\d+\]\s*/g, '')?.replace(/\|/g, '')}
                    </div>}
                    {translationLoading ? <div className="italic flex gap-2 items-center text-sm text-white/70"><l-quantum
                            size="45"
                            speed="3.75" 
                            color="#6360FF" 
                            ></l-quantum>
                            Traduction en cours ...</div> : !translationAvailable && <div className="flex "><div onClick={async(e) => {
                            e.stopPropagation()
                            setTranslationLoading(true)
                            await translateEvent(event); 
                            setTranslationLoading(false)
                            
                        }} className="px-2 bg-white text-indigo-500 rounded-xl mt-2 py-1">Traduire l'article</div></div>}

                    {translationAvailable && <div className="text-xs max-h-[30px] overflow-hidden ">
                        {event.data[`title_fr`] && <div className=' text-white/80 '>{cleanNumber(event.data[`title_fr`])}</div>}
                    </div> }
                </div> 
       
    </div>
    </div>
    <div className='flex flex-wrap items-center gap-2  mt-2'>

            {event.data[`content_${langCode}`] && <div className='bg-black/20 text-white/80 rounded-xl text-xs px-2  '>{readingTime(event.data?.[`content_${langCode}`])}</div>}
            <div className='bg-black/20 text-white/80 rounded-xl text-xs px-2  '>{event.source}</div>
            {/* <div className='bg-black/20 text-white/80 rounded-xl text-xs px-2  '>{event.nb_article} articles</div> */}
            <div className='bg-black/20 text-white/80 rounded-xl text-xs px-2  '>il y a {displayDuration(new Date(event.date), new Date())}</div>

        </div>
       
    
    </div>

}


const cleanNumber = (txt) => txt?.replace(/\[\d+\]\s*/g, '')?.replace(/\|/g, '') 

const Actuality = () => {
    const [events, setEvents] = useState([])
    const [dbEvents, setDbEvents] = useState([])
    const [selectedArticle, setSelectedArticle] = useState()

    const {workspace, text_50k, profile, setView, setText_50k ,setInt_50k,int_50k} = useStateContext()

    const langCode = workspace?.lang?.split('-')[0]


    useEffect(() => {
        if (selectedArticle) setView(<RenderArticle langCode={langCode} close={() => {setSelectedArticle(); setView()}} article={selectedArticle} />)
    }, [selectedArticle])


    useEffect(() => {
        dbEvents?.length == 0 && getEvent(setDbEvents)
        if (!text_50k) {
            setText_50k()
        }

        const get50k = async() => {
            console.log('get get50k')
            const data = await fetch50k(workspace)
            setInt_50k(data.int_50k)
            setText_50k(data.text_50k)
        }
     
        if (text_50k?.length == 0 ||  !text_50k ) get50k()
        
    }, [])


    



  

    const translate = async(text, lang_name) => {
        const prompt = `
        Donne juste la réponse. Traduit en ${lang_name} ce texte de manière naturelle comme si tu étais un ${lang_name}.  (laisse les "[1]" pour les phrases)
        Voici le texte à traduire : 
        ${text}
        `
        
        console.log('prompt', prompt);
        const request = [{ "role": "user", "content": prompt }];
        const requestData = await gpt(request, true, null, {model: "gpt-4o-mini"}); // Make sure gpt and ct are defined
        const data_content = requestData.choices[0]?.message?.content;
        console.log('data_content', data_content);

        return data_content
     
      }

      const translateEvent = async (event) => {
        const lang_code = workspace?.lang?.split('-')[0]
        const lang = workspace?.name

        const updates = {
          [`content_${lang_code}`]: await translate(event.data.content_fr, lang),
          [`title_${lang_code}`]: await translate(event.data.title_fr, lang),
          [`summary_${lang_code}`]: await translate(event.data.summary_fr, lang),
          [`title_article_${lang_code}`]: await translate(event.data.title_article_fr, lang)
        }
         console.log('updates', updates)
    

         setDbEvents(prev => prev.map(e => e.id == event.id ? {...event, data: {...event.data, ...updates}} : e))
        // update de l'event avec supabase uniquement les 
         sbUpdate('news_events', event.id, {data: {...event.data, ...updates}})

         return {...event, data: {...event.data, ...updates}}
  
      } 


   


    

  
    

    return <div className="relative">


        {profile?.role == "admin" && <div className="px-2 inline-block rounded-xl border border-b-2 mb-2 border-black/60" onClick={() => {
            getNews(setEvents)
        }}>Récupérer les évenements d'actualités</div>}

        {/* <div onClick={() => {
            getNews(setEvents)
        }}>Récupérer les évenements d'actualités</div>

        <div onClick={async() => {
        
            }}>{text_50k?.length}</div> */}

        {/* {selectedArticle && <RenderArticle langCode={langCode} close={() => setSelectedArticle()} article={selectedArticle} />} */}

        {dbEvents?.sort((a,b) => b.nb_article - a.nb_article)?.map(e => <RenderEventLine langCode={langCode} translateEvent={translateEvent} setSelectedArticle={setSelectedArticle} event={e} />)}
    </div>
}


export {Actuality}





