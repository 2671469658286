import { useEffect, useState } from "react";
import { useStateContext } from "../StateProvider";
import { clean } from "../helperFunctions";
import { supabase } from "../../V2/screens/testScreen";
import { gpt } from "../../utilities/helper/text";
import { createCardLang } from "../pages/AdminCards";
import { sbCreate } from "../DbFunction";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

const getLemma = async(term, lang) => {

    const text_request = `Donne uniquemnent la réponse.  
    Pour le terme suivant "${term}" en ${lang} donne son lemma si possible (si c'est un verbe donne l'infinif, un nom sa forme simple..) sans changer son type (nom, verbe, adverbe ..) si c'est déjà le lemma envoie le terme. 
    Si "${term}" n'est pas un mot ${lang} ou est un nom propre réponds "..."`
  
    const request = [{ "role": "user", "content": text_request}]

    const response = await gpt(request, true)

    const data_content = response?.choices[0]?.message?.content;

  
    return data_content
    
  }


const findWord = (cards, w) => {
    console.log('findWord', w)
    const transformedW = clean(w);


    return cards?.find(c => 
      clean(c.term) === transformedW || c.inflexions?.split(',').some(word => clean(word) === transformedW)
    );
  };

  async function searchInDb(word, workspace_id) {
    console.log('searchInDb', word)
    try {
      const { data, error } = await supabase
        .from('cards')
        .select('*')
        .eq('workspace_id', workspace_id)
        // .or(`term.ilike.${word},inflexions.ilike.%${word}%`);
        .or(`term.ilike.${word},inflexions.ilike.${word}`);
        // .or(`term.ilike.${word},inflexions.ilike.${word},inflexions.ilike.${word},%,inflexions.ilike.%,${word},%,inflexions.ilike.%,${word}`);

  
      if (error) throw error;
  
      if (data && data.length > 0) {
        // Filtre supplémentaire pour s'assurer que le mot correspond exactement
        const exactMatch = data.filter(card => {
            // Vérifie si le terme correspond exactement (insensible à la casse)
            if (card.term.toLowerCase() === word.toLowerCase()) return true;

            // Vérifie si le mot est dans les inflexions (comme mot exact)
            if (card.inflexions) {
                const inflexionsList = card.inflexions.split(',').map(infl => infl.trim());
                return inflexionsList.some(infl => 
                    infl.toLowerCase() === word.toLowerCase()
                );
            }
            return false;
        });

        return exactMatch.length > 0 ? exactMatch[0] : null;
    }
      
      return null;
  
    } catch (error) {
      console.error('Erreur lors de la recherche :', error);
      return null;
    }
  }




const foundCard = async(word, cards , workspace, set, text_50k, int_50k, setProgress) => {
    
     
    // 1 on recherche dans les cartes existantes
    let card = findWord(cards, word)
    if (card) { set(card); return}

    // 2 on recherche en ligne avec supa base
    card = await searchInDb(word, workspace.id)
    if (card) { set(card); return}

    // 2.bis on recherche en ligne si la carte est pas en blacklist

    // 3 on récupère le lemma
    const lemma = await getLemma(word, workspace.name)
    console.log('lemma', lemma)
    setProgress(2)

    // 3.bis 
    // si lemma n'existe pas c'est un nom propre, on ajoute donc le mot en black list
    if (lemma == "..."){
      
        return 
    }

    // 4 on recherche en local avec le lemma
    card = findWord(cards, lemma)
    if (card) { 
        // on update la carte en ajoutant le lemma dans inflexions
        set(card); 
        return}

     // 5 on recherche en lign avec le lemma
    card = await searchInDb(lemma, workspace.id)
    if (card) { 
        // on update la carte en ajoutant le lemma dans inflexions
        set(card); 
        return}

    console.log('la carte n existe pas il faut la créer !!')

    // la carte n'exite pas on la créer
    setProgress(5)
    card = await createCardLang(lemma, workspace, null, text_50k, int_50k, setProgress)
    const cardWithId = await sbCreate("cards", {...card, date: new Date(), data: {from: "magnifier"}})
    console.log('new card', card)
    setProgress(99)

 

    set(cardWithId)
    return card
        
}

function getSurroundingWords(text, targetWord, trad) {

    const wordsText = text?.split(/\s+/);
    const wordsTrad = trad?.split(/\s+/);
    const targetIndexText = wordsText.findIndex(word => word.toLowerCase()?.includes(targetWord?.toLowerCase()));
    
    if (targetIndexText === -1) {
        return "Word not found in the text.";
    }

    const length = 8
    const start = Math.max(0, targetIndexText - length);
    const end = Math.min(wordsText.length, targetIndexText + length+1);

    let trimmedText = ''
    let trimedTrad = ''

    if (targetIndexText < length) {
        trimmedText = wordsText.slice(0, length*2).join(' ').replace(/\|\|/g, '');;
        trimedTrad = wordsTrad?.slice(0, length*2).join(' ').replace(/\|\|/g, '');
    } else if (targetIndexText > wordsText.length -  length+1) {
        trimmedText = wordsText.slice(-length*2).join(' ').replace(/\|\|/g, '');;
        trimedTrad = wordsTrad?.slice(-length*2).join(' ').replace(/\|\|/g, '');;
    } else {
        trimmedText = wordsText.slice(start, end).join(' ').replace(/\|\|/g, '');;
        trimedTrad =  wordsTrad?.slice(start, end).join(' ').replace(/\|\|/g, '');;
    }

    return {
        trimmedText, trimedTrad
    }
}

const Magnifier = ({top, center}) => {


    const {magnifier, setMagnifier, setAlerts, learnedCards, setShowCard} = useStateContext()

    const [card, setCard] = useState()
    const [progress, setProgress] = useState()

   


    const userCard = learnedCards.find(e => e.profile_card.card_id == card?.id)
    const profile_card = learnedCards.find(e => e.profile_card.card_id == card?.id)?.profile_card



    const {workspace, text_50k, int_50k, profile} = useStateContext()

    const {sentence, word, sentence_trad, imgContext, path} = magnifier
    console.log('magnifier', magnifier)
    console.log('userCard', userCard)

    // console.log('sentence', sentence)
    const [showIndex, setShowIndex] = useState(0)
    const [lemma, setLemma] = useState()
    const [loading, setLoading] = useState()
    const [notWord, setNotWord] = useState(false)
    const [seeCard, setSeeCard] = useState(magnifier?.seeCard || false)
    const [contextUser, setContextUser] = useState()


    let sentencePrint = sentence
    let tradPrint = sentence_trad
    if (sentence?.split(' ')?.length > 16) {
        sentencePrint =  `...${getSurroundingWords(sentence, word, sentence_trad).trimmedText}...`
        tradPrint =  `...${getSurroundingWords(sentence, word, sentence_trad).trimedTrad}...`
    } 
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            await foundCard(magnifier.word, learnedCards.map(e => e.card), workspace, setCard, text_50k, int_50k, setProgress)
            setLoading(false)
        }
        
        fetchData()
    }, [magnifier.word])

  

    // const lemmaHandle = async(word) => {
    //     setLoading(true)
    //     console.log('word en trian de chanrgé', word)
       

    //     const __card = findWord(ct.cards, clean(word))
    //     console.log('__card', __card)
    //     const del_words= ct.fqlist?.del || []
    //     console.log('ct', ct.fqlist)
    //     console.log('__card', __card)
    //     console.log('del_words', del_words)

      
    //     if (__card) {
            
    //         setCard(__card)
    //         ct.setCards(cards => cards.map(card => card?.id === __card?.id ? {...card, inflexions: [...card?.inflexions, word]} : card))
    //         setLoading(false)
    //         return
    //     }

        

    //     if (del_words.includes(clean(word))) {
           
    //         setLoading(false)
    //         setMagnifier()
    //         return
    //     }



    //     const _lemma = await getLemma(word, ct.workspace.name, ct)
    //     setLemma(_lemma)
    //     const _card = findWord(ct.cards, clean(_lemma))
    //     console.log('lemma', lemma)
        
    //     if (_card) {
    //         setCard(_card)
    //         ct.setCards(cards => cards.map(card => card?.id === _card?.id ? {...card, inflexions: [...card?.inflexions || [], word]} : card))
    //         const updated = await updateDoc(doc(db, 'cards', _card?.id), {inflexions: [..._card?.inflexions || [], word]});
    //         return
    //     }
      
    //     if (_lemma == "...") {
    //         ct.setfqlist(fq => ({...fq, del: [...fq?.del || [], word]}))
    //         setLoading(false)
    //         setNotWord(true)
    //         setMagnifier()
    //         const newDels = [...del_words || [] , word]
        
    //         console.log('update de la liste del', ct.workspace?.name)
    //         console.log('newDels', newDels)
    //         const updated = await updateDoc(doc(db, "fq" , ct.workspace?.name), {del: newDels});
    //         return
    //     }


           

    //     if (!_card && _lemma != "..." ){ // si la carte existe en userCards ni dans le dico
    //             // rechercher la carte en ligne 
    //         const q = query(collection(db, 'cards'), where('workspace_id', "==", context.workspace?.id), where("term", "==", _lemma))
    //         const querySnapshot = await getDocs(q);
    //         const db_card = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}))?.[0]
    //         const contextsText = db_card?.contexts?.[0]?.text && db_card?.contexts?.[0]?.trad

    //         console.log('contextsText', contextsText)
    //         if (!contextsText && db_card?.id) {
              
    //         }
    //         // on recherche en ligne 
    //         if (db_card?.id && contextsText) {

                
                
    //             setCard(db_card)
    //              // si on trouve
    //             console.log('db_card', db_card)
    //             ct.setCards(cards => cards.map(card => card?.id === db_card?.id ? {...card, inflexions: [...card?.inflexions || [], word]} : card))
    //             updateDoc(doc(db, 'cards', db_card?.id), {inflexions: [...db_card?.inflexions || [], word]});
    //             return 
    //         }
          
    
           
    //         const more = await addMore({term: _lemma, lang: ct.workspace.name, ct})
          
    //         setCard({
    //             id: "test",
    //             term: _lemma,
    //             more: more,
    //             cm: "p",
    //             model: "lang-en",
    //             workspace_id: context.workspace?.id,
    //             lastUpdate: new Date(),
    //           })

    //         const bc_id = await addCat({term: _lemma, context: more?.definitions?.[0]?.ex?.[0].text, lang: ct.workspace.name, ct})
    //         const contexts = await completeCard(_lemma, ct.workspace.name, ct)
    //         setLoading(false)
    //         const inflexions = await addInflexions(_lemma, ct.workspace.name, ct)
    //         const data = await setRank({...card, term: _lemma, inflexions: [...inflexions, word]}, ct)


    //         if (db_card?.id && !contextsText) {
    //             console.log('on modifie la carte existante')
    //             console.log('carte avant', db_card)
    //             const updatedCard = {...db_card, 
    //                 more: more, 
    //                 inflexions: [...db_card?.inflexions || [], word],
    //                 lastUpdate: new Date(),
    //                 contexts: [contexts],
    //                 occ: data?.occ || null,
    //                 occ_tt: data?.occ_tt || null ,
    //                 rank_occ: data?.rank_occ || null,
    //                 bc_id: bc_id,
    //                 deck_id: db_card?.deck_id == "draft" ? null : db_card?.deck_deck_id
    //             }
    //             console.log('carte après', updatedCard)

    //             setCard(updatedCard)
    //             // si on trouve
    //             ct.setCards(cards => cards.map(card => card?.id === db_card?.id ? updatedCard : card))
    //             updateDoc(doc(db, 'cards', db_card?.id), {
    //                 more: more, 
    //                 inflexions: [...db_card?.inflexions || [], word],
    //                 lastUpdate: new Date(),
    //                 contexts: [contexts],
    //                 occ: data?.occ || null,
    //                 occ_tt: data?.occ_tt || null ,
    //                 rank_occ: data?.rank_occ || null,
    //                 bc_id: bc_id});
    //             return 

    //         }


    //         const newCard = {
    //             term: _lemma,
    //             more: more, 
    //             inflexions: [...inflexions, word],
    //             lastUpdate: new Date(),
    //             contexts: [contexts],
    //             cm: "p",
    //             model: "lang-en",
    //             workspace_id: context.workspace?.id,
    //             occ: data?.occ || null,
    //             occ_tt: data?.occ_tt || null ,
    //             rank_occ: data?.rank_occ || null,
    //             bc_id: bc_id
    //         }
          
    //         const cardId = await addDoc(collection(db, "cards"), newCard)
    //         setCard({...newCard, id: cardId.id})
    //         console.log('{...newCard, id: cardId.id}', {...newCard, id: cardId.id})
    //         context.setCards(cards => [...cards, {...newCard, id: cardId.id}])

        
          
            
    //     }
    // }
   

  




   
  if (!seeCard) {
    let _top = top+28
    let _left = center - 150
    let switched = false
    const width = window.innerWidth
    const height = window.innerHeight
   
    if (_left < 20) _left = 20
    let _right = _left + 290
    let diff = width - _right
    if (diff < 20) {
        _right = width - 20
        _left = _left + diff - 20
    }
    if (height - _top < 120 ) {
        _top -= 140
        switched = true
    }


    let defprint = null

    try{

    defprint = JSON.parse(card.definitions).definitions?.[0].trad_fr
   


    } catch (e) {

    }



   

    

    return (
        <div id="magnifier" onClick={() => {
            console.log('magnifier', magnifier)
            setMagnifier(null)
        }} className='no-last-time z-[999] px-4 flex flex-col items-center justify-center' style={{ position: "fixed", top: 0, right: 0, bottom: 0, left: 0, backgroundColor: "rgba(0, 10, 0, 0.1)", zIndex: 999}}>
            {!switched && <div style={{top: _top - 10  , left: center - 20}} className='absolute '>

            
            <svg width="23" height="10" viewBox="0 0 23 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 10C0 10 7.935 0 11.5 0C15.065 0 23 10 23 10H0Z" fill="#FFFFFF"/>
                </svg>
            </div>}

            {card?.id && <div onClick={(e) => e.stopPropagation()} style={{top: _top, left: _left, right: _right}} className='w-[290px] pt-1 text-center absolute bg-white z-[999]  rounded-xl fredoka mx-auto '>
              
              {/* {<div className='px-4 gap-3 py-2 text-left text-xs text-slate-500 '>{tradPrint}</div>} */}
              {clean(word) != clean(card.term) && <div className='text text-semibold '>{card.term}</div>}
              <div className="text-slate-500 text-center border-b pb-1">{defprint}</div>
                <div className='px-2 gap-3 py-2 text-left text-[11px] text-slate-500 '>{tradPrint}</div>

              <div className='flex bg-slate-200 w-full rounded-b-xl overflow-hidden'>
                {!profile_card?.id && <div className='bg-[#10cb95]  p-2 px-4 text-white'>
                    Déjà connu
                </div> }
                <div onClick={(e) => {
                      setMagnifier()
                      setShowCard({card: card, profile_card: {
                          ...profile_card,
                          profile_id: profile.id,
                          workspace_id: workspace.id,
                          context: {
                              img: imgContext,
                              trad: tradPrint,
                              context: sentencePrint,
                              path: path

                          }
                      }})
                    }} className='bg-purple-500 text-white grow p-2'>{profile_card?.id ? "Voir la carte" : "Apprendre" }</div>
                </div>
            </div>}
            {!card && <div onClick={(e) => e.stopPropagation()} style={{top: _top, left: _left, right: _right}} className="w-[290px]  text-center absolute bg-white z-[999]  p-2  rounded-xl fredoka mx-auto ">      
            {loading ? <div className="relative flex gap-2 items-center">
                <l-helix
                    size="40"
                    speed="2.5" 
                    color="#6360FF" 
                    ></l-helix>
                 <div>
                    <div className='text-left text-sm text-indigo-600 flex items-center gap-1'>Création de la carte  <CircularProgressbar
                            value={progress || 0}
                            className='circularProgress transition absolute -top-3 right-0  !h-6'
                            styles={buildStyles({
                                strokeLinecap: 'butt',
                                strokeWidth: 2,
                                textSize: '0px',
                                pathColor: "#6360FF",
                                textColor: '#45446233',
                                pathTransitionDuration: 0.5,
                                strokeLinecap: 'round', 
                                trailColor: '#45446233',
                                backgroundColor: '#45446233',
                                transform: 'rotateX(30deg)'
                            })}
                            strokeWidth={8}

                    /></div>
                    <div className='text-left text-xs text-slate-500 '>{tradPrint}</div>
                 </div>
                 </div> : <div>Non disponible</div>
                 }
            </div>}
          
            {switched && <div style={{top: _top + 104  , left: center - 20}} className='absolute z-[0] rotate-[180deg]'>
                
                <svg width="23" height="10" viewBox="0 0 23 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 10C0 10 7.935 0 11.5 0C15.065 0 23 10 23 10H0Z" fill="#FFFFFF"/>
                    </svg>
                </div> }
        </div>
          
  )}
  
  
};

export {Magnifier, searchInDb}