import { useEffect, useState } from "react"
import { getNextCards, sbCreate, sbDelete, sbUpdate } from "../DbFunction"
import { useStateContext } from "../StateProvider"
import { WordDefinition } from "../components/flashcard/flashcard"
import { getLemma, gpt, imageGenerator } from "../../utilities/helper/text"
import { extractFirstBracketContent, fetch50k, hasWordInBrackets } from "../helperFunctions"
import { getFunctions, httpsCallable } from "firebase/functions"
import { app } from "../../config"
import { supabase } from "../../V2/screens/testScreen"
import { SentenceBuilder } from "../components/triggers/Sentence"
import { toeicPaths } from "../helperData"
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"
import GIF from 'gif.js';
import { SVG_IA } from "../svgs"
import { searchInDb } from "../components/Magnifier"


const sendTaskMidJourney = (prompt, setTask) => {

    console.log('sendTaskMidJourney', `${prompt}, in  illustration, –style illustration -v 6.1`)

    var myHeaders = new Headers();
    myHeaders.append("x-api-key", "45d542a56733c185e472075a1a6f03fca8eb64e9a07d7018c4a21767187f7eae");
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
       "model": "midjourney",
       "task_type": "imagine",
       "input": { 
          "prompt": `${prompt} in a poetic hand draw 2D vector illustration style`,
          "aspect_ratio": "3:2",
          "process_mode": "fast",
          "skip_prompt_check": true,
          "bot_id": 0
       },
       "config": {
          "service_mode": "",
          "webhook_config": {
             "endpoint": "https://webhook.site/",
             "secret": "123456"
          }
       }
    });
    
    var requestOptions = {
       method: 'POST',
       headers: myHeaders,
       body: raw,
       redirect: 'follow'
    };
    console.log('fetch midjourney', requestOptions);
    fetch("https://api.piapi.ai/api/v1/task", requestOptions)
       .then(response => response.json())
       .then(result => {
        setTask(result.data)
        console.log(result)})
       .catch(error => console.log('error', error));

}


const getTask = (taskId, setTask) => {
    console.log('getTask', taskId)
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", "45d542a56733c185e472075a1a6f03fca8eb64e9a07d7018c4a21767187f7eae");
    var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
    };

    fetch(`https://api.piapi.ai/api/v1/task/${taskId}`, requestOptions)
    .then(response => response.json())
    .then(result => {
        setTask(result.data)
        console.log('result', result.data)
    })
    .catch(error => console.log('error', error));
}


const addDalleImg = async(url, updateCard, storage, card) => {
    try {
        const result = await httpsCallable(functions, 'proxyImage')({ image: url });
        const uint8Array = new Uint8Array(result.data.image);
        const blob = new Blob([uint8Array], { type: result.data.contentType });
        const imageUrl2 = URL.createObjectURL(blob);
        
        const response = await fetch(imageUrl2);
        const imageBlob = await response.blob();
        
        // Création et redimensionnement de l'image
        const resizedBlob = await new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(imageBlob);
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = 500;
                canvas.height = 500;
                ctx.drawImage(img, 0, 0, 500, 500);
                canvas.toBlob(resolve, 'image/webp');
            };
            img.onerror = reject;
        });

        // Upload du fichier
        const storageRef = ref(storage, `dalle3_img/500/${card.workspace_id || "mnemo"}-${card.id}`);
        const snapshot = await uploadBytes(storageRef, resizedBlob);
        
        // Obtention de l'URL
        const downloadURL = await getDownloadURL(snapshot.ref);
        
        if (updateCard && card.term) {
            updateCard({...card, img: downloadURL, imgs: null});
            updateAllCards({img: downloadURL, imgs: null}, card.img);
        }
        
        return downloadURL;
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
};


  const getDalleImg = async(p, set) => {
    console.log('prompt', p)
    const dataImg = await imageGenerator({
        "model": "dall-e-3",
        "prompt": p,
        "n": 1,
        "size": "1024x1024"
      })
    const url = dataImg.data[0].url
    
    set(url)
    
}


const sendToDb = async (url,storage, card, index) => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();

        // Ajouter l'index dans le nom du fichier pour garantir l'unicité
        const storageRef = ref(storage, `mid/800/${card.id}-${index}.webp`);

        const snapshot = await uploadBytes(storageRef, blob);
        console.log(`Uploaded part ${index}!`);

        const downloadURL = await getDownloadURL(snapshot.ref);
        return downloadURL;
    } catch (error) {
        console.error(`Error uploading file part ${index}:`, error);
        throw error;
    }
}

const sendToDbTemp = async (url,storage, card, index) => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();

        // Ajouter l'index dans le nom du fichier pour garantir l'unicité
        const storageRef = ref(storage, `mid/temp/${index}.webp`);

        const snapshot = await uploadBytes(storageRef, blob);
        console.log(`Uploaded part ${index}!`);

        const downloadURL = await getDownloadURL(snapshot.ref);
        return downloadURL;
    } catch (error) {
        console.error(`Error uploading file part ${index}:`, error);
        throw error;
    }
}

const functions = getFunctions(app);

const loadProxyImage = httpsCallable(functions, 'proxyImage');


async function splitImage(imageUrl, updateCard, storage, card, setLoad = ()=>{}) {
    console.log('splitImage', card)
    setLoad(p => ([...p, "proxied"]))
    const result = await httpsCallable(functions, 'proxyImage')({ image: imageUrl });
    const uint8Array = new Uint8Array(result.data.image);

// Créer le blob
    const blob = new Blob([uint8Array], { type: result.data.contentType });
    const imageUrl2 = URL.createObjectURL(blob);
    console.log(' dimageUrl2',  imageUrl2);
    setLoad(p => (p || [])?.filter(e => e != "proxied"))
  
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = imageUrl2;
    console.log('img', img)
    
    
    await new Promise(resolve => img.onload = resolve);
    console.log('splitImage loaded')


    // Calculer les nouvelles dimensions en conservant le ratio
    let newWidth = img.width;
    let newHeight = img.height;
    const maxSize = 1600;

    if (newWidth > maxSize || newHeight > maxSize) {
        if (newWidth > newHeight) {
            newHeight = (newHeight / newWidth) * maxSize;
            newWidth = maxSize;
        } else {
            newWidth = (newWidth / newHeight) * maxSize;
            newHeight = maxSize;
        }
    }

    // Ajuster les dimensions pour respecter le ratio 3:2
    if (newWidth / newHeight > 3/2) {
        newWidth = newHeight * (3/2);
    } else {
        newHeight = newWidth / (3/2);
    }

    // Créer un canvas temporaire pour le redimensionnement initial
    const tempCanvas = document.createElement('canvas');
    const tempCtx = tempCanvas.getContext('2d');
    tempCanvas.width = newWidth;
    tempCanvas.height = newHeight;
    tempCtx.drawImage(img, 0, 0, newWidth, newHeight);

    // Créer un canvas pour les parties
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Définir les dimensions pour chaque partie
    const partWidth = newWidth / 2;
    const partHeight = newHeight / 2;
    canvas.width = partWidth;
    canvas.height = partHeight;

    // Coordonnées pour chaque partie
    const parts = [
        { x: 0, y: 0 },         // Haut gauche
        { x: partWidth, y: 0 }, // Haut droite
        { x: 0, y: partHeight }, // Bas gauche
        { x: partWidth, y: partHeight } // Bas droite
    ];

    // Diviser l'image en 4 parties
    const images = parts.map((part, index) => {
        ctx.clearRect(0, 0, partWidth, partHeight);
        ctx.drawImage(
            tempCanvas,
            part.x, part.y,
            partWidth, partHeight,
            0, 0,
            partWidth, partHeight
        );
        
        return canvas.toDataURL('image/webp', 0.75);
    });


    setLoad(p => ([...p, "upload-split"]))
    const uploadPromises = images.map((dataUrl, index) => sendToDb(dataUrl, storage, card, index));
    console.log('splitImage splited')

   
    try {
        const uploadedUrls = await Promise.all(uploadPromises);
        const finalImages = uploadedUrls.map(url => url);

        console.log('succes', finalImages)
        console.log('first', finalImages[0])
        
        updateCard({...card, imgs: finalImages});
        updateAllCards({imgs: finalImages}, card.img) ;
        setLoad(p => (p || [])?.filter(e => e != "upload-split"))
        
        return finalImages;
    } catch (error) {
        console.error('Erreur lors de l\'upload des images:', error);
        throw error;
    }
}


const shuffleArray = (array) => {
    for (let i = array?.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };


function transformText(text) { return text?.toLowerCase()?.replace(/[.,!?;¡::/¿()“"'\n\r]/g, "").replaceAll('a:', '').replaceAll('b:', '')}

const getRank = async (term, inflexions, text_50k, int_50k) => {

    if (!text_50k) return

    console.log('get rank', {term, inflexions, text_50k, int_50k})


    // const { data, error } = await supabase
    // .from('rank_term')
    // .select('term, occurence, rank:occurence')
    // .or(inflexions.map(inflexion => `term.ilike.%${inflexion}%`).join(','))
    // .select('*, rank:row_number() over (order by occurence)')
    // .order('occurence', { ascending: true })
    // .limit(1);

    const indices = [];
    const occ = []
    const inflexionArray = [...new Set([...inflexions?.split(',').map(e => e.trim())?.map(e => transformText(e)) || [], transformText(term)])];
    // console.log('inflexionArray', inflexionArray)

  
    inflexionArray.forEach(inflexion => {

        const index = text_50k?.indexOf(transformText(inflexion));

        if (index !== -1) {
            indices.push(index);
            occ.push(int_50k[index])
        }
    });


    if (indices.length > 0) {
        const minIndex = Math.min(...indices);
        // console.log('Index le plus petit:', minIndex); 
        // console.log('1er occ', int_50k[minIndex])
        const occ_tt = occ.reduce((acc, val) => parseInt(acc) + parseInt(val), 0);
     
        
      
        return {
            occ_tt: occ_tt,
            rank_occ: minIndex
        }
    
    } else {

        console.log('Aucune inflexion trouvée dans text_50k pour', term);
       return {
            occ_tt: 0,
            rank_occ: null
        }
    }
}

const randomiseForm = (randomNumber) => {
    let perso = ""
    let form = ""

    if (randomNumber >= 6) {
        perso = `en utilisant l'un des pronom suivant au hasard (${shuffleArray(["nous", "vous", "ils/elles", "tu", "je", "je", "tu", "on", "il/elle", "il/elle"])[0]}))`
        console.log("perso", perso)
    }
    if (randomNumber >= 3 && randomNumber <= 4) {
       
        form = shuffleArray(["dans la forme interrogative", "dans la forme négative", "dans la forme formelle"])[0]
    }

    return {perso, form}
}

const createContext = async(word, langName) => {
        const randomNumber = Math.floor(Math.random() * 10) + 1;
        const {perso, form} = randomiseForm(randomNumber)
        const text_request = `Donne uniquemnent la réponse sans markdown.  Tu es un professeur ${langName}.
        Pour le terme suivant "${word}" en ${langName} : 
        Donne une phrase simple et natuelle qui a du sens, en ${langName}, qui contient le terme ${word} tel quel, sans inflexion si possible (pas de pluriel, pas de conjugaison, verbe à l'infinitif etc..) 
        en utilisant un contexte de la vie quotienne ${perso} ${form}.
        Met "${word}" entre [] dans la phrase.
        `
        const request = [{ "role": "user", "content": text_request}]
        const requestData = await gpt(request, true, null, {model: "gpt-4o"})
        return requestData.choices[0]?.message?.content

}

const getEty = async(word,langName) => {
    const text_request = `
    Pour le mot "${word}" en ${langName} donne l'étymologie du mot. Dans un format très court sans markdown. Donne directement la composition avec la traduction entre () sans réécrire le mot. 
    Si pas de compositon écris "..." 
    
    exemple pour  Spaziergang "Spazier (promenade) + Gang (marche) "
    exemple pour Feuer "..."
    `
    const request = [{ "role": "user", "content": text_request}]
    const requestData = await gpt(request, true, null, {model: "gpt-4o"})
    return requestData.choices[0]?.message?.content
}

const translateContext = async(sentence, word, langName) => {
    const text_request = `
    Donne uniquement la réponse, sans markdown. Tu es un professeur de français.
    Traduit de manière naturelle la phrase suivante en français : "${sentence}" et met la traduction du mot ${word} entre [].
    `
    const request = [{ "role": "user", "content": text_request}]
    const requestData = await gpt(request, true, null, {model: "gpt-4o"})
    return requestData.choices[0]?.message?.content
}

const varyContext = async(sentence, word, langName) => {
    const text_request = `
    Donne uniquement la réponse, sans markdown. Tu es un professeur ${langName}.
    La phrase doit contenir le mot "${word}". Améliore la phrase suivante: "${sentence}" en ${langName} pour quelle soit plus naturelle.
    Met le "${word}" entre []. Si la phrase est déjà correct renvoie la phrase tel quel.
    `
    const request = [{ "role": "user", "content": text_request}]
    const requestData = await gpt(request, true, null, {model: "gpt-4o"})
    return requestData.choices[0]?.message?.content
}
 


const createInflexions = async(word, langName) => {

    const text_request = `Donne uniquement la réponse, sans markdown. Tu es un professeur ${langName}.
    Quelle forme peux prendre le mot "${word}" en ${langName} ? 
    Donne la liste des inflexions du mot  séparé par des virgules, uniquement les formes du mot, sans déterminant ni article.
    Toutes les formes possibles du mots sans doublons. En changeant de genre et en accordant si besoin. N'invente pas de nouveau terme ! 
    Toutes les inflexions doivent exister.`
    

    const request = [{ "role": "user", "content": text_request}]
    const requestData = await gpt(request, true, null, {model: "gpt-4o"})
    return requestData.choices[0]?.message?.content

   
}

const createCardLang = async (lemma, workspace, deck_id = null, text_50k, int_50k, setProgress) => {
    console.log('createCardLang', lemma);
    console.log('workspace', workspace);
    
    setProgress(36);
    
    // Use Promise.all() to run independent async operations in parallel
    const [context, inflexions, note, rank] = await Promise.all([
      createContext(lemma, workspace.name),
      createInflexions(lemma, workspace.name),
      createNote(lemma, workspace.name),
      getRank(lemma, await createInflexions(lemma, workspace.name), text_50k, int_50k)
    ]);
    
    setProgress(80);
    
    const [context_fr, definitions] = await Promise.all([
      translateContext(context, lemma, workspace.name),
      formatNote(note, workspace.name)
    ]);
    
    setProgress(95);
  
    return {
      workspace_id: workspace.id,
      note,
      definitions,
      inflexions,
      date: new Date(),
      context,
      term: lemma,
      context_fr,
      model: "lang", 
      ...rank
    };
  };



  const updateAllCards = async (updatedData, oldImg) => {

    console.log('updateAllCards', updateAllCards)

    const { data, error } = await supabase
    .from('cards')
    .select('*')
    .eq('img', oldImg)

    data.forEach(async(c) => {
        console.log('card to update', {c, updatedData})
        if (updatedData) sbUpdate('cards', c.id, {...updatedData})
    })

}

const getWords = async (workspace_id) => {

    console.log('updateAllCards', updateAllCards)

    const { data, error } = await supabase
    .from('cards')
    .select('term, inflexions')
    .eq('workspace_id', workspace_id)
    return data

    console.log('data', data)
}


const WordDefinitions = ({definitions}) => {

    const [state, setState] = useState(0)

    try {
        
        return <div className="text-left text-[0.9rem]">{JSON.parse(definitions)?.definitions?.map((def, i) => <div>
            <div className="mt-4 mb-2">
                {/* <span className="bg-white/10 text-[12px] border border-amber-500/40 rounded-xl text-amber-500/50 px-1 mr-[6px]">{i}</span> */}
                {def.trad_fr} - <span className="italic text-xs italic opacity-[0.8]">{def.pos}</span></div>
            <div className="pl-2">
            <div className="text-purple-500" dangerouslySetInnerHTML={{ __html: def.context?.replace(/\[([^\]]+)\]/g, '<span class="text-indigo-500 font-semibold">$1</span>') }} ></div>
            <div className="mb-2 italic text-xs opacity-[70%]" dangerouslySetInnerHTML={{ __html: def.context_trad_fr?.replace(/\[([^\]]+)\]/g, '<span class="text-amber-900/90 ">$1</span>') }}></div>
            <div className="mt-2 flex gap-2 text-xs flex-wrap">{def?.synonymes?.split(',').map(syn => <div className="bg-indigo-400/10 rounded-xl text-indigo-400 px-1">{syn}</div>)}</div>
            </div>
            </div>)}    
        </div>
    } catch(error) {
        return <div className="text-red-500">Erreur de format</div>
    }
    
   
}


// Faire une fonction qui créer une carte automatiquement 
// vérification le terme existe en db 
// création du lemma
// vérification si le lemma est en db
// création du contexte 
// création de la note 
// création de la thématique
// création du rank
// création d une image

// EditableImage 
// Faire logo pour faire des générations 
// Faire logo pour 4 générations 


// Faire fonction autoBrackets
// Bracket automatiquement les contextes sans braquets 

// Faire fonction get note 
// créer une note puis met dans le bon format 
// tester GPT 

// Optimiser le premier exercice de learn 


// ajouter check IA


const formatNote = async (note, lang) => {

    const text_request = `
    Formate cette note "${note}". Ne créer pas plusieurs définitions qui ont le même sens.
    Dans le format json suivant sans markdown 
    {note: "...", 
    definitions: [
        {pos: "" // part of speech (Noun,Determiner,Adjective, Pronoun, Verb, Adverb, Preposition, Conjunction, Interjection), 
        trad_fr: "",
        context: "", // exemple avec le mot entre []
        context_trad_fr: "", avec la traduction du mot entre []
        usage: "", // le % de fréquence d'utilisation de cette definition
        synonymes: "" // en ${lang} séparé par des , 
     }]}”
    `
    console.log('text_request', text_request)
    const request = [{ "role": "user", "content": text_request}]

    const requestData = await gpt(request, true, null, null, null)
    const data_content = requestData.choices[0]?.message?.content;


    console.log('data_content formatNote', data_content)

    return data_content
   
}

const generateNoteClaude = async({term,lang}) => {
    const text_request = `
    Que veut dire "${term}" dans un texte en ${lang} ? Donne juste la réponse illustré d'exemples.”
    `
    console.log('text_request', text_request)
    const request = [{ "role": "user", "content": text_request}]
  
    const requestData = await callClaude(request, {});

    const data_content = requestData.data.content?.[0]?.text

    console.log('data_content claude', data_content)

}


const createNote = async(term,lang) => {
    const text_request = `
    Tu es un professeur de langue ${lang}. Que veut dire "${term}" dans un texte en ${lang} ? Donne juste la réponse illustré d'exemple.”
    `
    console.log('text_request', text_request)
    const request = [{ "role": "user", "content": text_request}]

    const requestData = await gpt(request, true, null, {model: "gpt-4o"}, null)
    const data_content = requestData.choices[0]?.message?.content;
    return data_content
}


const generateNote = async({card, term, lang, updateCard}) => {
    const data_content = await createNote(term, lang)

    console.log('data_content', data_content)
    const formatedNote = await formatNote(data_content, lang)
    updateCard && updateCard({...card, definitions: formatedNote, note: data_content})
    sbUpdate('cards', card.id, {note: data_content, definitions: formatedNote})
}


const autoBrackets = async({card, term, context, context_fr, lang, updateCard}) => {
    const text_request = `
    Tu es un professeur ${lang}.
    Met des "[" ou "]" autour du mot qui réprésente "${term}" en ${lang} dans la phrase en ${lang} : "${context?.replace(/[\[\]]/g, '')}" (et reformule cette phrase si elle n'est pas naturelle ou pas correct en ${lang}).
    et dans sa traduction naturelle en français. 
    Donne uniquement la réponse, sans markdown :
    {"context": "..." // avec le mot [], "context_fr": "..." // avec la traduction entre []}
    `

    console.log('text_request', text_request)
    const request = [{ "role": "user", "content": text_request}]

    const requestData = await gpt(request, true, null, {model: "gpt-4o"}, null)
    const data_content = requestData.choices[0]?.message?.content;

    console.log('data_content', data_content)
    console.log('json', JSON.parse(data_content))
    updateCard({...card, ...JSON.parse(data_content)})
    sbUpdate('cards', card.id, JSON.parse(data_content))
}

const getPromptImage = async (sentence, word, set, auto) => {

    const text_request = `
    Donne une description très courte (max 1 lignes) d'une illustration pour imager le mot suivant "${word}" tu peux t'aider de la phrase suivante : "${sentence}" et qui met le focus sur le mot . 
    L'image ne doit pas avoir de texte ni mot écris dessus. Donne la description en anglais`
    console.log('text_request', text_request)
    const request = [{ "role": "user", "content": text_request}]

    const requestData = await gpt(request, true, null, null, null)
    const data_content = requestData.choices[0]?.message?.content;


    console.log('data_content formatNote', data_content)
    set(data_content)

    return data_content
   
}

const RenderCardLine = ({card, setCards, index}) => {
    const storage = getStorage(app);
    const {workspace} = useStateContext()
    const [task, setTask] = useState()


    const [dalle3_img, setDalle3_img] = useState()

    const [imgPrompt, setImgPrompt] = useState()


    const defaultPromt = `Donne moi une image qui peut représenter le terme '${card.term}' dans le style illustration morderne, simple, clean lines avec un fond léger (pas trop chargé ou neutre). 
    Si tu ne trouve pas d'image pour le term tu peux t'aider de la phrase suivante '${card.context}'. 
    Aucuns mots ni écriture, ni lettre ne doit ne doit apparaitre sur l'image !!`

    const defaultPromptWord = `Donne moi une image qui peut représenter le terme '${card.term}' dans le style illustration morderne, simple, clean lines avec un fond léger (pas trop chargé ou neutre). Sans texte sur l'image`

    const updateCard = (data) => {
        console.log('updateCard', data)
        setCards(prev => prev.map(c => c.id == card.id ? {...c, ...data} : c))
    } 


    const deleteCard = (card) => {
        console.log('deleteCard', card)
        setCards(prev => prev.filter(c => c.id!= card.id))
        sbDelete('cards', card.id)
    }

  

    const replaceImage = (url) => {
        setTask()
        updateCard({...card, img: url});
        updateAllCards({img: url}, card.img);
    }


    useEffect(() => {
        console.log('task', task)
        if (!task?.task_id) return 
    
        let interval; // Déclarer l'interval en dehors du setInterval
    
        if (task?.output?.progress === 100 || task?.output?.image_url) {
            clearInterval(interval)
            return
        } 
        
        interval = setInterval(() => {
            getTask(task?.task_id, setTask)
            console.log('get task')
        }, task?.status == "processing" ? 3000 : 15000)
    
        // Cleanup function pour arrêter le polling quand le composant est démonté
        return () => clearInterval(interval)
    }, [task?.status, task?.task_id])
    

    

    return <div key={card.id} onClick={() => {
        // console.log('card', card)
    }} className="p-4 border-b-2 border-black/10 flex gap-2 w-[1200px] relative">
                <div>
               
              {/* <div  onClick={async () => {
                    console.log('runaway')
                    try {
                        const response = await fetch('https://us-central1-mindseed-425a8.cloudfunctions.net/runway', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ img: card.img })
                        });

                        if (!response.ok) {
                            throw new Error(`HTTP error! status: ${response.status}`);
                        }

                        const data = await response.json();
                        console.log('data', data)
                        return data;
                    } catch (error) {
                        console.error('Error:', error);
                        throw error;
                    }

                }} >RUNWAY</div> */}

{/* <div onClick={async() => {
     try {
        const response = await fetch('https://us-central1-mindseed-425a8.cloudfunctions.net/runwayTask', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: "acb4e387-001e-4589-bdfa-ab19729ecd62" })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const video_url = data.data.output?.[0]
        console.log('data', data)
        console.log('video', video_url)

        const gif=  await convertVideoToGif(video_url)
        console.log('gif', gif)
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }

}}>TASK RUNWAY</div> */}

                <div className="absolute top-0 left-0 text-xs text-slate-300">{index}</div>
                
                <div>
                    <input onBlur={(e) => sbUpdate('cards', card.id, {rank: parseInt(e.currentTarget.value)})} className="w-[50px] px-1 rounded-xl border" placeholder="rank" defaultValue={card?.rank}  />
                    <input className="w-[50px] px-1 rounded-xl border" type="checkbox"  placeholder="rank" defaultValue={card.isValid} />

                </div>
                
                <div className="flex">
                    <div className="relative">
                    <img src={card.img} className="h-[80px] w-[100px] object-cover  border-2 rounded-xl" />
                    {card?.imgs?.length && <div className="bottom-2 px-2 rounded-xl bg-black/50 right-2 text-xs text-white absolute">+ {card?.imgs?.length}</div>}
                    </div>
                </div>
                <div className="border p-2 rounded-xl bg-yellow-50">
                <div className="flex gap-1 mb-1">
                    {<div className="border text-pink-500 text-xs rounded px-1 hover:scale-125" onClick={() => {sendTaskMidJourney(defaultPromptWord, setTask)}}>M-6</div>}
                    {<div className="border text-pink-500 text-xs px-1 rounded hover:scale-125" onClick={() => getDalleImg(defaultPromptWord, setDalle3_img)}>D-3</div>}
                </div>
                <div className="flex gap-1 mb-1">
                    {<div className="border text-slate-500 text-xs rounded px-1 hover:scale-125" onClick={() => {sendTaskMidJourney(imgPrompt || defaultPromt, setTask)}}>M-6</div>}
                    {<div className="border text-slate-500 text-xs px-1 rounded hover:scale-125" onClick={() => getDalleImg(null, setDalle3_img)}>D-3</div>}
                </div>

                <div className="text-amber-500 flex gap-1 mb-1">
                    <div onClick={() => {getPromptImage(card.context, card.term, setImgPrompt)}}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4 hover:scale-125">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                        </svg>
                    </div>
                    <div className="text-[10px] text-amber-500">{imgPrompt}</div>
                    <div onClick={async() => {
                        const prompt = await getPromptImage(card.context, card.term, setImgPrompt)
                        sendTaskMidJourney(prompt, setTask)
                        }}>Auto</div>
                </div>

                {task?.output?.image_url && <img onClick={() => {splitImage(task?.output?.image_url, updateCard, storage, card)}} src={task?.output?.image_url} className="h-[100px] hover:scale-[2] p-2 bg-indigo-500" />}
                {task?.task_id && task.output.progress != 100 && <div  className="border bg-indigo-500 text-xs text-white hover:scale-125 h-[100px] w-[100px] rounded-xl text-center flex justify-center items-center" onClick={() => {getTask(task.task_id, setTask)}}>{task.status}... {task.output.progress}%</div>}

             

                


                {!card.imgs?.find(url => card.img == url) && card.imgs?.length> 0 && <div className="p-2 border bg-indigo-500 text-white rounded-xl">
                    <div className="flex relative ">
                    {card.imgs?.map((img, i) => <div className="relative">
                        <img key={i} src={img} className={`h-[90px] min-w-[120px] ${card.img == img ? "" : "scale-[0.8]"}  hover:scale-[2] transition-all rounded-xl`} />
                                    {card.img == img ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" strokeWidth={1.5} stroke="#fff" className="h-6 hover:scale-[1.2] top-4 left-4 absolute text-yellow-500">
                        <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                        </svg>
            : 
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#15141280" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 hover:scale-[1.2] top-4 left-4 absolute text-yellow-100" onClick={() => {replaceImage(img)}}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                        </svg>}

                    </div>)}
                    </div>

                    <div>

                    </div>
                    <div onClick={() => {
                          updateCard({...card, imgs: null});
                          updateAllCards({imgs: null}, card.img);
                    }}>Supprimer</div>
                    </div>}
                {dalle3_img && <div className="relative">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#15141280" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 hover:scale-[1.2] top-4 left-4 absolute text-yellow-100" onClick={() => {addDalleImg(dalle3_img, storage, card)}}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                        </svg>
                    <img src={dalle3_img} className="h-[100px] w-[100px]" /></div>}
                    </div> 
                </div>
                
                <div>
                <div className={`w-[100px] ${card.isInflexion ? "text-pink-500" : "" }`}>{card.term}</div>
                <div className={`w-[100px] text-slate-400 text-xs italic`}> <SVG_IA onClick={async() => {
                         const ety = await getEty(card.term,workspace?.name)

                         if (ety.includes("...")) return

                         updateCard({...card, data: {...card.data, ety} })
                         sbUpdate("cards", card.id, {data: {...card.data, ety}})
                    }} />
                     {card?.data?.ety} {card?.data?.ety && <span className="px-2 rounded-xl border text-pink-500" onClick={() => {

                        updateCard({...card, data: {...card.data, ety: null} })
                        sbUpdate("cards", card.id, {data: {...card.data, ety: null}})
                     }}>x</span>}
                     </div>
                </div>

                <div className={`w-[40px] h-[24px] hover:scale-[1.1] cursor-pointer flex justify-center items-center px-1 rounded-xl ${card.hasBrackets ? "text-green-500 bg-green-100 " : "bg-red-500 text-red-100"}`} onClick={() => {
                    autoBrackets({card, term: card.term, context: card.context, context_fr: card.context_fr, lang: workspace.name, updateCard: updateCard})
                    }} >
                    [...] 
                </div>
                <div className="w-[300px]">
                    <div className="flex gap-2 ">

                    <div onClick={async() => {
       
       const context = await varyContext(card.context, card.term, workspace?.name)
       const translatedSentence = await translateContext(context, card.term, workspace?.name)

       console.log('context variation', context)
       updateCard({...card, context: context, context_fr:translatedSentence })
       sbUpdate("cards", card.id, {context_fr: context, context_fr:translatedSentence})

   }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 text-indigo-500 ">
   <path fillRule="evenodd" d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z" clipRule="evenodd" />
 </svg>
 
 
 </div>
                        <div onClick={async() => {
       
                            const context = await varyContext(card.context, card.term, workspace?.name)
                            const translatedSentence = await translateContext(context, card.term, workspace?.name)

                            console.log('context variation', context)
                            updateCard({...card, context: context, context_fr:translatedSentence })
                            sbUpdate("cards", card.id, {context_fr: context, context_fr:translatedSentence})

                        }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 text-indigo-500 ">
                        <path fillRule="evenodd" d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z" clipRule="evenodd" />
                      </svg>
                      
                      
                      </div>
                    
                    <div  className="text-indigo-500" 
                        dangerouslySetInnerHTML={{ __html: card.context?.replace(/\[([^\]]+)\]/g, '<span class="text-pink-500 font-semibold">$1</span>') }}
                        ></div>


                        </div>

                    <div className="flex gap-2 "><div onClick={async() => {
                        const translatedSentence = await translateContext(card.context, card.term, workspace?.name)
                        console.log('translatedSentence', translatedSentence)
                        updateCard({...card, context_fr: translatedSentence})
                        sbUpdate("cards", card.id, {context_fr: translatedSentence})
                    }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 text-indigo-500 ">
                    <path fillRule="evenodd" d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z" clipRule="evenodd" />
                  </svg>
                  
                  
                  </div> 
                    <div className="w-[300px]"><div className="opacity-50 text-xs" 
                        dangerouslySetInnerHTML={{ __html: card.context_fr?.replace(/\[([^\]]+)\]/g, '<span class="text-pink-600 font-semibold">$1</span>') }}
                        >
                            

                        </div>
                        <input key={card.id + "ct_fr"} onBlur={(e) => sbUpdate('cards', card.id, {context_fr: e.currentTarget.value})} className="w-full px-1 text-xs rounded-xl border" placeholder="rank" defaultValue={card?.context_fr}  />
                        </div>
                        </div>
                    {/* <SentenceBuilder sentence={card.context} word={extractFirstBracketContent(card.context) || card.term} /> */}
                </div>
                <div className={`w-[40px] h-[24px] hover:scale-[1.1] cursor-pointer flex justify-center items-center px-1 rounded-xl ${card.note ? "text-green-500 bg-green-100 " : "bg-red-500 text-red-100"}`} onClick={() => {
                    generateNote({card, term: card.term, context: card.context, context_fr: card.context_fr, lang: workspace.name, updateCard: updateCard})
                    }} >
                    Note
                </div>
                <div className="w-[200px] bg-yellow-100 rounded-xl p-1  !text-[10px] h-[70px] hover:h-auto overflow-scroll">{card.note}</div>

                <div className="w-[300px]  !text-[12px] h-[70px] overflow-scroll hover:h-auto"><WordDefinitions definitions={card.definitions} /></div>
                <div  className="bg-red-100 text-red-500 px-3 rounded-xl " onClick={() => {deleteCard(card)}}>Supprimer</div>
        </div>
}


const formatCard = (card) => {
    const hasBrackets = hasWordInBrackets(card.context) && hasWordInBrackets(card.context_fr) 
    const isInflexion = extractFirstBracketContent(card.context)?.toLowerCase() !== card.term.toLowerCase()
    let note = card.note
    try {
        JSON.parse(card.definitions)
    } catch(error) {
        note = null
    }
    return {...card, hasBrackets, note, isInflexion}
}

// Au lieu d'utiliser httpsCallable, utilisez fetch directement
async function callClaude(prompt, opt = {}) {
    console.log('callClaude', prompt)
    try {
        const response = await fetch('https://us-central1-mindseed-425a8.cloudfunctions.net/claude', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ prompt, opt })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('data', data)
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

// Utilisation


async function getCardsCountByWorkspaceId(workspaceId) {
    try {
      const { count } = await supabase
        .from('cards')
        .select('*', { count: 'exact' })
        .eq('workspace_id', workspaceId);
      
      return count || 0;
    } catch (error) {
      console.error('Erreur lors du comptage des cartes:', error);
      throw error;
    }
  }


  const loadCardsWithDeck = async (workspaceId,deck_id, start, end) => {
    console.log('loadCards', workspaceId)
    console.log('start', start)
    console.log('end', end)
    try {
        const { data, error } = await supabase
            .from('cards') // Remplacez par le nom de votre table de cartes
            .select('*') // Sélectionnez les colonnes dont vous avez besoin
            .eq('workspace_id', workspaceId) // Filtrer par workspace
            .eq('model', "lang") 
            .eq('deck_id', deck_id)
            .order('is_valid', { ascending: false }) // Trier d'abord par isVerified (les vérifiés en premier)
            .order('rank_occ', { ascending: true }) // Puis par rank
            .range(start, end) // Récupérer du 50ème au 100ème élément (inclus)

        if (error) {
            console.error('Erreur lors de la récupération des cartes:', error)
            return null
        }
        console.log('data', data)

        return data
    } catch (error) {
        console.error('Erreur inattendue:', error)
        return null
    }
}

const loadCards = async (workspaceId, start, end) => {
    console.log('loadCards', workspaceId)
    console.log('start', start)
    console.log('end', end)
    try {
        const { data, error } = await supabase
            .from('cards') // Remplacez par le nom de votre table de cartes
            .select('*') // Sélectionnez les colonnes dont vous avez besoin
            .eq('workspace_id', workspaceId) // Filtrer par workspace
            .eq('model', "lang") 
            .order('is_valid', { ascending: false }) // Trier d'abord par isVerified (les vérifiés en premier)
            .order('rank', { ascending: true, nullsLast: true }) // Ensuite par rank (avec les null à la fin)
            .order('rank_occ', { ascending: true }) // Puis par rank
            .range(start, end) // Récupérer du 50ème au 100ème élément (inclus)

        if (error) {
            console.error('Erreur lors de la récupération des cartes:', error)
            return null
        }
        console.log('data', data)

        return data
    } catch (error) {
        console.error('Erreur inattendue:', error)
        return null
    }
}

const AdminCards = () => {


    const {workspace, profile, workspaces, setWorkspace} = useStateContext()
    const [cards, setCards] = useState()
    const [totalNumber, setTotalNumber]= useState(0)
    const [currentPageIndex, setCurrentPageIndex]=useState(0)
    const [currentDeck, setCurrentDeck]= useState()

    const loadCard = async (workspaceId, start, end) => {
        const totalNumber = await getCardsCountByWorkspaceId(workspaceId)
        setTotalNumber(totalNumber)
        setCurrentDeck(false)
        const cards = await loadCards(workspaceId, start, end)
        if (cards) {
            setCards(cards)
        }
    }

    const loadCardwithDeck = async (workspaceId,deck_id, start, end ) => {
    
       
        const cards = await loadCardsWithDeck(workspaceId, deck_id, start, end)
        if (cards) {
            setTotalNumber(cards?.length)
            setCards(cards)
        }
    }


    const [int_50k, setInt_50k] = useState()
    const [text_50k, setText_50k] = useState()
    const get50k = async(workspace) => {
        console.log('get get50k')
        const data = await fetch50k(workspace)
        setInt_50k(data?.int_50k)
        setText_50k(data?.text_50k)
    }

    
    useEffect(() => {
        workspace?.id && loadCard(workspace.id, 0, 100)
        workspace?.id && get50k(workspace)
        
    }, [workspace?.id])
  

    const formatedCards = cards?.map(formatCard)
    const noBracketCards = formatedCards?.filter(e => !e.hasBrackets)



    console.log('noBracketCards', noBracketCards)
    
    
    const updateCard = (newCard) => {
        setCards(prev => prev.map(c => c.id == newCard.id ? {...newCard} : c))
    } 

    const itemsPerPage = 500;

    // Calcul du nombre total de pages
    const totalPages = Math.ceil(totalNumber / itemsPerPage);

    const pagination = Array.from({ length: totalPages }, (_, index) => {
        const start = index * itemsPerPage + 1;
        const end = Math.min((index + 1) * itemsPerPage, totalNumber);
        return {
          page: index + 1,
          start,
          end,
          items: end - start + 1
        };
      });


      const [page, setPage] = useState("word")
      
      const [words, setWords] = useState([])




    return <div className="p-8 bg-white max-h-screen overflow-scroll pb-40">
       {/* <div>{workspace?.name}</div> */}
       <div className="flex gap-2 mt-2 ">{workspaces.map(e => <div className="flex gap-2 items-center" onClick={() => {
        setWorkspace(e)
        setCurrentDeck()
        setCards([])
        }}><img src={e.img} className="h-5 w-5 rounded-full" /> {e.name}</div>)}</div>

  
        
       
    

       {/* <div onClick={async() => {
        
        // generateNoteClaude({term: "minute", lang: "anglais"})
        const request = [{ "role": "user", "content": `
        Que veut dire "green" dans un texte en anglais ? Donne juste la réponse illustré d'exemple.”
        `}]
  
        const requestData = await callClaude(request, {});
        const content = requestData.data.content?.[0]?.text
        console.log('content claude : ', content)
       }}>test générate note avec claude</div> */}

       <div className="flex gap-2">
            <div onClick={() => {setPage('pareto')}}>Vue pareto</div>
            <div onClick={() => {setPage('classic')}}>Vue liste</div>
        </div>

        {page == "classic" && <>

       <div className="flex gap-2 text-base mt-2 gap-2">
      
        <div className="btn-base" onClick={loadCard}>Charger les cartes débutants</div>


       

        {noBracketCards?.length > 0 && <div onClick={() => {
                noBracketCards?.forEach(card => {
                    autoBrackets({card, term: card.term, context: card.context, context_fr: card.context_fr, lang: workspace.name, updateCard: updateCard})
                })}} className="btn-base !bg-pink-500">Corriger les {noBracketCards?.length || 0} cartes sans brackets</div>
        }

        {formatedCards?.filter(e => !e.note).length > 0&& <div onClick={() => {
        formatedCards?.filter(e => !e.note)?.forEach(card => {
            generateNote({card, term: card.term, context: card.context, context_fr: card.context_fr, lang: workspace.name, updateCard: updateCard})
        })
       }} className="btn-base !bg-pink-500">{formatedCards?.filter(e => !e.note).length} Mise à jour des notes </div>}
      

       <div onClick={() => {
        cards?.forEach(card => {
            autoBrackets({card, term: card.term, context: card.context, context_fr: card.context_fr, lang: workspace.name, updateCard: updateCard})
        })
       }} className="btn-base">All - Mise à jour brackets [..]</div>
       
       <div onClick={() => {
            cards?.forEach(async (card) => {
                const context_fr = await translateContext(card.context, card.term, workspace.name);
                updateCard({...card, context_fr: context_fr});
                sbUpdate('cards', card.id, { context_fr: context_fr });
            });
       }} className="btn-base">GOOD TRAD</div>

       
        </div>
        <div className="flex gap-2 text-base mt-2 gap-2">
        {toeicPaths.map(e => <div className={`px-2 ${e?.id == currentDeck ? "bg-indigo-500 text-white" : ""}  rounded-xl border`} onClick={() => {
            loadCardwithDeck(workspace.id, e.id, 0, 100 )
            setCurrentDeck(e.id)
        }}>{e.name}</div>)}
        </div>
       <div className="flex gap-1 my-1">{pagination?.map((e,i) => <div className={`${currentPageIndex == i ? "bg-indigo-500 text-white" : "text-indigo"} px-2 rounded-xl cursor-pointer`}  onClick={() => {loadCard(workspace.id, e.start, e.end); setCurrentPageIndex(i)}}>{i + 1}</div>)}</div>
       <div className="p-4">
        {formatedCards?.map((card, index) => <RenderCardLine key={card.id} index={index} card={card}  setCards={setCards} />)}
       </div>
       </>}

       {page == "pareto" && <div>

            <div onClick={()=> {console.log('text_50k', text_50k)}}>Pareto</div>
            <div onClick={async()=> {
                    const words = await getWords(workspace?.id)
                    const wordsFormated = words?.map(e => (e.term + "," + e.inflexions).split(',')).flat()
                    console.log('wordsFormated', wordsFormated)
                    setWords(wordsFormated)

            }}>Get words workspace {workspace?.id}</div>

            <div>words : {words?.length}</div>

            <div className="flex flex-wrap gap-1 ">

            {text_50k.slice(0, 2000).map(word => <div onClick={async() => {
                const lemma = await getLemma(word, workspace.name)
                if (lemma == "..."){
                    // ajouter dans la liste noire
                    return 
                }
                // tester si c'est dans la grammaire et ajouter dans liste grammaire 
                const inDb = await searchInDb(lemma, workspace.id)
                if (inDb) {
                    console.log('card inDb', inDb)
                    sbUpdate('cards', inDb.id, {inflexions: inDb.inflexions+","+word})
                    setWords(prev => [...prev, word])
                // modifier les inflexions pour inclure le words
                    return}
                const card = await createCardLang(lemma, workspace, null, text_50k, int_50k, () => {})
                const cardWithId = await sbCreate("cards", {...card, date: new Date(), data: {from: "pareto"}})
                const newWords = [card.term, ...(card.inflexions?.split(',') || [])]
                setWords(prev => [...prev, ...newWords])
                console.log('card created', {card, newWords, cardWithId})

            }} className={`${words.find(w => word.toLowerCase() == w.toLowerCase()) ? "bg-green-500" : ""} px-1 border`}>{word}</div>)}
            </div>
            
        </div>}
      

    </div>
}


export {AdminCards, WordDefinitions, generateNote, createCardLang, varyContext, translateContext, 
    updateAllCards, getPromptImage, splitImage, addDalleImg, getDalleImg, sendTaskMidJourney, getTask, getEty
}